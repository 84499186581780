<template>
    <div class="account">
        <div class="userTitle">
            <span>{{ $t('xin-zeng') }}</span>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: adminPath }">{{ $t('ge-ren-zhong-xin') }}</el-breadcrumb-item>

                <el-breadcrumb-item :to="{ path: '/mineOrder' }">{{
                    $t('chan-pin-xu-qiu-cheng-jiao-dan')
                }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ form.id ? $t('bian-ji') : $t('xin-zeng') }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <p class="dividingLine"></p>
        <div class="main" v-loading="loading">
            <el-form
                label-width="164px"
                size="small"
                :model="form"
                style="width: 800px"
                ref="form"
                :rules="rules"
                hide-required-asterisk
            >
                <el-form-item prop="type" :label="$t('qing-dan-lei-xing')">
                    <el-input :value="$t('chan-pin-jiao-yi-deng-ji')" readonly></el-input>
                </el-form-item>
                <el-form-item prop="chName" :label="$t('chan-pin-ming-cheng')" :rules="rulesRequire">
                    <el-input
                        v-model="form.chName"
                        :placeholder="$t('qing-shu-ru-chan-pin-ming-cheng')"
                        class="input2"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="brand" :label="$t('chan-pin-pin-pai')" :rules="rulesRequire">
                    <el-input
                        v-model="form.brand"
                        :placeholder="$t('qing-shu-ru-chan-pin-pin-pai-ming')"
                        class="input2"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="model" :label="$t('chan-pin-xing-hao')" :rules="rulesRequire">
                    <el-input
                        v-model="form.model"
                        :placeholder="$t('qing-shu-ru-chan-pin-xing-hao')"
                        class="input2"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="productCategoryId" :label="$t('chan-pin-lei-bie')" :rules="rulesRequire">
                    <el-cascader
                        v-model="form.productCategoryId"
                        :props="optionProps"
                        :options="categories"
                        :placeholder="$t('qing-xuan-ze-chan-pin-lei-bie')"
                        class="input2"
                        :show-all-levels="false"
                    >
                    </el-cascader>
                </el-form-item>

                <el-form-item prop="applicationField" :label="$t('ying-yong-ling-yu')">
                    <ApplicationField  class="input2" :ids.sync="form.applicationField" :label.sync="form.customApplicationField" />
                </el-form-item>
                <el-form-item prop="introduction" :label="$t('chan-pin-miao-shu')">
                    <el-input
                        type="textarea"
                        v-model="form.introduction"
                        :autosize="{ minRows: 4, maxRows: 8 }"
                        :maxlength="200"
                        :placeholder="$t('qing-jian-yao-gai-shu-chan-pin-gong-neng-200-zi-yi-nei')"
                        show-word-limit
                        class="input3"
                        resize="none"
                    ></el-input>
                </el-form-item>

                <el-form-item prop="transactionCompany" :label="$t('jiao-yi-gong-si-ming')" :rules="rulesRequire">
                    <!-- <el-input
                        v-model="form.transactionCompany"
                        :placeholder="$t('qing-shu-ru-jiao-yi-gong-si-ming')"
                        class="input2"
                    ></el-input> -->

                    <el-autocomplete
                        popper-class="my-autocomplete"
                        v-model="form.transactionCompany"
                        :fetch-suggestions="querySearch"
                        :placeholder="$t('qing-shu-ru-jiao-yi-gong-si-ming')"
                        @select="handleSelect"
                        class="input2"
                        clearable
                        :popper-append-to-body="false"
                    >
                        <template slot-scope="{ item }">
                            <div class="name" v-html="getImport(item, form.transactionCompany)"></div>
                        </template>
                    </el-autocomplete>
                </el-form-item>

                <el-form-item prop="transactionTime" :label="$t('jiao-yi-shi-jian')" :rules="rulesRequire">
                    <el-date-picker
                        v-model="form.transactionTime"
                        type="datetime"
                        :placeholder="$t('xuan-ze-ri-qi-shi-jian')"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        class="input2"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item prop="price" :label="$t('huo-zhi')" :rules="rulesRequire">
                    <el-input v-model="form.price" placeholder="0.00" class="input2" @change="formatPrice">
                        <template slot="prepend">$</template>
                    </el-input>
                </el-form-item>

                <el-form-item prop="number" v-if="form.id" :label="$t('bian-hao')">
                    <el-input v-model="form.number" readonly></el-input>
                </el-form-item>

                <el-form-item prop="customsHsCode" :label="$t('hai-guan-bian-ma')">
                    <el-input
                        v-model="form.customsHsCode"
                        :maxlength="10"
                        :placeholder="$t('qing-shu-ru-10-wei-hai-guan-bian-ma')"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="entryRecordNumber" :label="$t('jin-jing-bei-an-dan-hao')" :rules="numberFormat">
                    <el-input
                        v-model="form.entryRecordNumber"
                        :maxlength="18"
                        :placeholder="$t('qing-shu-ru-18-wei-bian-hao')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="importDeclarationNumber"
                    :label="$t('jin-kou-bao-guan-dan-hao')"
                    :rules="rulesRequire"
                >
                    <el-input
                        v-model="form.importDeclarationNumber"
                        :maxlength="18"
                        :placeholder="$t('qing-shu-ru-18-wei-bian-hao')"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="exitRecordNumber" :label="$t('chu-jing-bei-an-dan-hao')" :rules="numberFormat">
                    <el-input
                        v-model="form.exitRecordNumber"
                        :maxlength="18"
                        :placeholder="$t('qing-shu-ru-18-wei-bian-hao')"
                    ></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="warning" @click="onSubmit" :loading="loading">{{ $t('ti-jiao') }}</el-button>
                    <el-button @click="onDelete" size="small" :loading="loading" type="danger" v-if="form.id">
                        {{ $t('shan-chu') }}
                    </el-button>
                    <el-button v-else plain type="info" @click="saveStorage" :loading="loading">
                        {{ $t('bao-cun') }}
                    </el-button>
                    <el-button plain type="info" @click="$router.go(-1)">{{ $t('fan-hui') }}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { productListType } from '../../utils/AppState';
import { matchingName } from '../../utils/CommonApi';
import formPage from '../../mixins/formPage';
import ProductCategory from '../../components/select/ProductCategory';
import ApplicationField from '../../components/select/ApplicationField';
export default {
    name: 'orderEdit',
    mixins: [formPage],
    data() {
        return {
            form: {
                type: '',
                transactionTime: ''
            },
            optionProps1: {
                value: 'id',
                label: 'chName',
                children: 'children',
                multiple: true,
                emitPath: false
            },
            categories: [],
            tags: [],
            rules: {
                applicationField: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value || this.form.customApplicationField) {
                                callback();
                            } else {
                                callback(' ');
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                customsHsCode: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value && /[0-9]{10}/g.test(value)) {
                                callback();
                            } else if (!value) {
                                callback(this.$t('qing-shu-ru'));
                            } else {
                                callback(this.$t('ge-shi-cuo-wu'));
                            }
                        },
                        trigger: 'blur'
                    }
                ]
            },
            numberFormat: {
                required: true,
                validator: (rule, value, callback) => {
                    if (value && /[0-9]{18}/g.test(value)) {
                        callback();
                    } else if (!value) {
                        callback(this.$t('qing-shu-ru'));
                    } else {
                        callback(this.$t('ge-shi-cuo-wu'));
                    }
                },
                trigger: 'blur'
            }
        };
    },
    mounted() {
        if (this.$route.query.id) {
            this.loading = true;
            this.$http
                .get('/productsTransaction/get/' + this.$route.query.id)
                .then(res => {
                    delete res.productCategory;
                    this.form = res;
                })
                .finally(() => {
                    this.loading = false;
                });
        } else if (window.localStorage.getItem('productsTransactionEdit_' + this.$store.state.userInfo.id)) {
            const orderEdit = JSON.parse(
                window.localStorage.getItem('productsTransactionEdit_' + this.$store.state.userInfo.id)
            );
            this.form = orderEdit.form;
        }

        this.$http
            .get('/productCategory/allList')
            .then(res => {
                this.categories = this.getTreeData(res, 0);
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
    },
    methods: {
        submit() {
            const form = { ...this.form };

            if (!form.id) {
                form.userId = this.$store.state.userInfo.id;
                window.localStorage.removeItem('productsTransactionEdit_' + this.$store.state.userInfo.id);
            }

            this.$http
                .post('/productsTransaction/save', form, { body: 'json' })
                .then(res => {
                    this.$message.success(form.id ? this.$t('xiu-gai-cheng-gong') : this.$t('xin-zeng-cheng-gong'));
                    this.$router.go(-1);
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        },
        onDelete() {
            this.$alert(this.$t('shan-chu-jiang-wu-fa-hui-fu-que-ren-yao-shan-chu-mo'), this.$t('jing-gao'), {
                type: 'error'
            })
                .then(() => {
                    return this.$http.post(`/productsTransaction/del/${this.form.id}`);
                })
                .then(() => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error(e.error);
                    }
                });
        },
        saveStorage() {
            const orderEdit = {
                form: this.form
            };
            window.localStorage.setItem(
                'productsTransactionEdit_' + this.$store.state.userInfo.id,
                JSON.stringify(caseEdit)
            );
            this.$message.success(this.$t('bao-cun-cheng-gong'));
            history.back();
        },
        getTreeData(data, num = 0, disabledNum = 2) {
            // 循环遍历json数据
            for (let i = 0; i < data.length; i++) {
                if (num < disabledNum) {
                    data[i].disabled = true;
                }
                if (data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children, num + 1, disabledNum);
                }
            }
            return data;
        },
        formatPrice(val) {
            this.form.price = this.number_format(val, 2).replace(/,/g, '');
        },
        number_format(number, decimals, dec_point, thousands_sep) {
            /*
             * 参数说明：
             * number：要格式化的数字
             * decimals：保留几位小数
             * dec_point：小数点符号
             * thousands_sep：千分位符号
             * */
            number = (number + '').replace(/[^0-9+-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
                sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
                dec = typeof dec_point === 'undefined' ? '.' : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.ceil(n * k) / k;
                };

            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            var re = /(-?\d+)(\d{3})/;
            while (re.test(s[0])) {
                s[0] = s[0].replace(re, '$1' + sep + '$2');
            }

            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        },
        handleSelect(item) {
            this.form.transactionCompany = item;
        },
        querySearch(queryString, cb) {
            console.log(queryString);
            var results = [];
            if (queryString) {
                matchingName(queryString, 'brand').then(res => {
                    let searchstr = queryString.toLowerCase();
                    results = [
                        ...new Set(
                            res.map(item => {
                                if (
                                    (this.isChinese && item.chName.toLowerCase().indexOf(searchstr) !== -1) ||
                                    (!this.isChinese && item.enName.toLowerCase().indexOf(searchstr) === -1)
                                ) {
                                    return item.chName;
                                } else {
                                    return item.enName;
                                }
                            })
                        )
                    ];

                    cb(results);
                });
            } else {
                cb(results);
            }
        }
    },
    computed: {
        collectTypes() {
            return [...productListType]
                .map(item => {
                    return {
                        key: item[0],
                        name: item[1]
                    };
                })
                .filter(item => {
                    if (this.isVendor && !this.form.id && item.key === 'OWN_PRODUCT') {
                        return false;
                    } else {
                        return true;
                    }
                });
        },
        optionProps() {
            return {
                value: 'id',
                label: this.$t('chname'),
                children: 'children',
                multiple: false,
                emitPath: false,
                checkStrictly: true,
                expandTrigger: 'hover'
            };
        }
    },
    components: {
        ProductCategory,
        ApplicationField
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.userTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main {
    padding: 20px;
}
.el-button {
    padding: 0 20px;
    line-height: 40px;
    min-width: 120px;
    margin-top: 32px;
}

.el-form {
    margin: 20px 0;
}
.el-button--info {
    background: transparent;
    min-width: 120px;
}
.userTitle {
    border: none;
}
.dividingLine {
    height: 1px;
    background-color: #dcdfe6;
    margin: 0 auto;
    width: 97%;
}
</style>
